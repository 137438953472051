import { default as _91name_93HYE0HT3IiRMeta } from "D:/office_work/95help_new/help-95dot-com-frontend/pages/[name].vue?macro=true";
import { default as aboutusqfczs0LFLhMeta } from "D:/office_work/95help_new/help-95dot-com-frontend/pages/aboutus.vue?macro=true";
import { default as indexCZea3Cf0NaMeta } from "D:/office_work/95help_new/help-95dot-com-frontend/pages/admin/account/index.vue?macro=true";
import { default as accountOrD4fELkJLMeta } from "D:/office_work/95help_new/help-95dot-com-frontend/pages/admin/account.vue?macro=true";
import { default as indexy8ftXHMrB1Meta } from "D:/office_work/95help_new/help-95dot-com-frontend/pages/admin/index.vue?macro=true";
import { default as adminkRCB8cPXXDMeta } from "D:/office_work/95help_new/help-95dot-com-frontend/pages/admin.vue?macro=true";
import { default as affiliateyEz4t6978kMeta } from "D:/office_work/95help_new/help-95dot-com-frontend/pages/affiliate.vue?macro=true";
import { default as cancellationandrefundpolicyMVnRhseLumMeta } from "D:/office_work/95help_new/help-95dot-com-frontend/pages/cancellationandrefundpolicy.vue?macro=true";
import { default as _91id_939fKJX23RELMeta } from "D:/office_work/95help_new/help-95dot-com-frontend/pages/card/[id].vue?macro=true";
import { default as contact_45us5vvgwYgfQ2Meta } from "D:/office_work/95help_new/help-95dot-com-frontend/pages/contact-us.vue?macro=true";
import { default as cookiepolicy18AuoKwDCrMeta } from "D:/office_work/95help_new/help-95dot-com-frontend/pages/cookiepolicy.vue?macro=true";
import { default as demoetHDpUInlQMeta } from "D:/office_work/95help_new/help-95dot-com-frontend/pages/demo.vue?macro=true";
import { default as extraworkingUGJivVUIeuMeta } from "D:/office_work/95help_new/help-95dot-com-frontend/pages/extraworking.vue?macro=true";
import { default as forgot_45passworduq1BQ9fmH6Meta } from "D:/office_work/95help_new/help-95dot-com-frontend/pages/index/forgot-password.vue?macro=true";
import { default as indexLkz37thPfVMeta } from "D:/office_work/95help_new/help-95dot-com-frontend/pages/index/index.vue?macro=true";
import { default as verify_45emailfWxV4wGFYZMeta } from "D:/office_work/95help_new/help-95dot-com-frontend/pages/index/verify-email.vue?macro=true";
import { default as verify1YcyGBDbw4Meta } from "D:/office_work/95help_new/help-95dot-com-frontend/pages/index/verify.vue?macro=true";
import { default as indexQFlBjdhx92Meta } from "D:/office_work/95help_new/help-95dot-com-frontend/pages/index.vue?macro=true";
import { default as jobsbBu0p3b54MMeta } from "D:/office_work/95help_new/help-95dot-com-frontend/pages/jobs.vue?macro=true";
import { default as loginZya9CSE8fHMeta } from "D:/office_work/95help_new/help-95dot-com-frontend/pages/login.vue?macro=true";
import { default as _91orderId_93gQ3pQLNgJ7Meta } from "D:/office_work/95help_new/help-95dot-com-frontend/pages/order/[orderId].vue?macro=true";
import { default as cartQf3ExWWbQDMeta } from "D:/office_work/95help_new/help-95dot-com-frontend/pages/order/cart.vue?macro=true";
import { default as checkoutLMpdCwDdI8Meta } from "D:/office_work/95help_new/help-95dot-com-frontend/pages/order/checkout.vue?macro=true";
import { default as indexCk0KGUkNMVMeta } from "D:/office_work/95help_new/help-95dot-com-frontend/pages/order/index.vue?macro=true";
import { default as ordercartaWqGWMzlVVMeta } from "D:/office_work/95help_new/help-95dot-com-frontend/pages/ordercart.vue?macro=true";
import { default as privacypolicyzzPk1JIjoyMeta } from "D:/office_work/95help_new/help-95dot-com-frontend/pages/privacypolicy.vue?macro=true";
import { default as productswv9Mxme1VEMeta } from "D:/office_work/95help_new/help-95dot-com-frontend/pages/products.vue?macro=true";
import { default as register289UE8vvniMeta } from "D:/office_work/95help_new/help-95dot-com-frontend/pages/register.vue?macro=true";
import { default as setup_45profilejObaCJXsIbMeta } from "D:/office_work/95help_new/help-95dot-com-frontend/pages/setup-profile.vue?macro=true";
import { default as shippingandorderpolicydQbH5jUi7KMeta } from "D:/office_work/95help_new/help-95dot-com-frontend/pages/shippingandorderpolicy.vue?macro=true";
import { default as _91id_93Dp86bHE0KyMeta } from "D:/office_work/95help_new/help-95dot-com-frontend/pages/tag/[id].vue?macro=true";
import { default as termsofusePzypsOabHyMeta } from "D:/office_work/95help_new/help-95dot-com-frontend/pages/termsofuse.vue?macro=true";
export default [
  {
    name: _91name_93HYE0HT3IiRMeta?.name ?? "name",
    path: _91name_93HYE0HT3IiRMeta?.path ?? "/:name()",
    meta: _91name_93HYE0HT3IiRMeta || {},
    alias: _91name_93HYE0HT3IiRMeta?.alias || [],
    redirect: _91name_93HYE0HT3IiRMeta?.redirect || undefined,
    component: () => import("D:/office_work/95help_new/help-95dot-com-frontend/pages/[name].vue").then(m => m.default || m)
  },
  {
    name: aboutusqfczs0LFLhMeta?.name ?? "aboutus",
    path: aboutusqfczs0LFLhMeta?.path ?? "/aboutus",
    meta: aboutusqfczs0LFLhMeta || {},
    alias: aboutusqfczs0LFLhMeta?.alias || [],
    redirect: aboutusqfczs0LFLhMeta?.redirect || undefined,
    component: () => import("D:/office_work/95help_new/help-95dot-com-frontend/pages/aboutus.vue").then(m => m.default || m)
  },
  {
    path: adminkRCB8cPXXDMeta?.path ?? "/admin",
    children: [
  {
    path: accountOrD4fELkJLMeta?.path ?? "account",
    children: [
  {
    name: indexCZea3Cf0NaMeta?.name ?? "admin-account",
    path: indexCZea3Cf0NaMeta?.path ?? "",
    meta: indexCZea3Cf0NaMeta || {},
    alias: indexCZea3Cf0NaMeta?.alias || [],
    redirect: indexCZea3Cf0NaMeta?.redirect || undefined,
    component: () => import("D:/office_work/95help_new/help-95dot-com-frontend/pages/admin/account/index.vue").then(m => m.default || m)
  }
],
    name: accountOrD4fELkJLMeta?.name ?? undefined,
    meta: accountOrD4fELkJLMeta || {},
    alias: accountOrD4fELkJLMeta?.alias || [],
    redirect: accountOrD4fELkJLMeta?.redirect || undefined,
    component: () => import("D:/office_work/95help_new/help-95dot-com-frontend/pages/admin/account.vue").then(m => m.default || m)
  },
  {
    name: indexy8ftXHMrB1Meta?.name ?? "admin",
    path: indexy8ftXHMrB1Meta?.path ?? "",
    meta: indexy8ftXHMrB1Meta || {},
    alias: indexy8ftXHMrB1Meta?.alias || [],
    redirect: indexy8ftXHMrB1Meta?.redirect || undefined,
    component: () => import("D:/office_work/95help_new/help-95dot-com-frontend/pages/admin/index.vue").then(m => m.default || m)
  }
],
    name: adminkRCB8cPXXDMeta?.name ?? undefined,
    meta: adminkRCB8cPXXDMeta || {},
    alias: adminkRCB8cPXXDMeta?.alias || [],
    redirect: adminkRCB8cPXXDMeta?.redirect || undefined,
    component: () => import("D:/office_work/95help_new/help-95dot-com-frontend/pages/admin.vue").then(m => m.default || m)
  },
  {
    name: affiliateyEz4t6978kMeta?.name ?? "affiliate",
    path: affiliateyEz4t6978kMeta?.path ?? "/affiliate",
    meta: affiliateyEz4t6978kMeta || {},
    alias: affiliateyEz4t6978kMeta?.alias || [],
    redirect: affiliateyEz4t6978kMeta?.redirect || undefined,
    component: () => import("D:/office_work/95help_new/help-95dot-com-frontend/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: cancellationandrefundpolicyMVnRhseLumMeta?.name ?? "cancellationandrefundpolicy",
    path: cancellationandrefundpolicyMVnRhseLumMeta?.path ?? "/cancellationandrefundpolicy",
    meta: cancellationandrefundpolicyMVnRhseLumMeta || {},
    alias: cancellationandrefundpolicyMVnRhseLumMeta?.alias || [],
    redirect: cancellationandrefundpolicyMVnRhseLumMeta?.redirect || undefined,
    component: () => import("D:/office_work/95help_new/help-95dot-com-frontend/pages/cancellationandrefundpolicy.vue").then(m => m.default || m)
  },
  {
    name: _91id_939fKJX23RELMeta?.name ?? "card-id",
    path: _91id_939fKJX23RELMeta?.path ?? "/card/:id()",
    meta: _91id_939fKJX23RELMeta || {},
    alias: _91id_939fKJX23RELMeta?.alias || [],
    redirect: _91id_939fKJX23RELMeta?.redirect || undefined,
    component: () => import("D:/office_work/95help_new/help-95dot-com-frontend/pages/card/[id].vue").then(m => m.default || m)
  },
  {
    name: contact_45us5vvgwYgfQ2Meta?.name ?? "contact-us",
    path: contact_45us5vvgwYgfQ2Meta?.path ?? "/contact-us",
    meta: contact_45us5vvgwYgfQ2Meta || {},
    alias: contact_45us5vvgwYgfQ2Meta?.alias || [],
    redirect: contact_45us5vvgwYgfQ2Meta?.redirect || undefined,
    component: () => import("D:/office_work/95help_new/help-95dot-com-frontend/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: cookiepolicy18AuoKwDCrMeta?.name ?? "cookiepolicy",
    path: cookiepolicy18AuoKwDCrMeta?.path ?? "/cookiepolicy",
    meta: cookiepolicy18AuoKwDCrMeta || {},
    alias: cookiepolicy18AuoKwDCrMeta?.alias || [],
    redirect: cookiepolicy18AuoKwDCrMeta?.redirect || undefined,
    component: () => import("D:/office_work/95help_new/help-95dot-com-frontend/pages/cookiepolicy.vue").then(m => m.default || m)
  },
  {
    name: demoetHDpUInlQMeta?.name ?? "demo",
    path: demoetHDpUInlQMeta?.path ?? "/demo",
    meta: demoetHDpUInlQMeta || {},
    alias: demoetHDpUInlQMeta?.alias || [],
    redirect: demoetHDpUInlQMeta?.redirect || undefined,
    component: () => import("D:/office_work/95help_new/help-95dot-com-frontend/pages/demo.vue").then(m => m.default || m)
  },
  {
    name: extraworkingUGJivVUIeuMeta?.name ?? "extraworking",
    path: extraworkingUGJivVUIeuMeta?.path ?? "/extraworking",
    meta: extraworkingUGJivVUIeuMeta || {},
    alias: extraworkingUGJivVUIeuMeta?.alias || [],
    redirect: extraworkingUGJivVUIeuMeta?.redirect || undefined,
    component: () => import("D:/office_work/95help_new/help-95dot-com-frontend/pages/extraworking.vue").then(m => m.default || m)
  },
  {
    path: indexQFlBjdhx92Meta?.path ?? "/",
    children: [
  {
    name: forgot_45passworduq1BQ9fmH6Meta?.name ?? "index-forgot-password",
    path: forgot_45passworduq1BQ9fmH6Meta?.path ?? "forgot-password",
    meta: forgot_45passworduq1BQ9fmH6Meta || {},
    alias: forgot_45passworduq1BQ9fmH6Meta?.alias || [],
    redirect: forgot_45passworduq1BQ9fmH6Meta?.redirect || undefined,
    component: () => import("D:/office_work/95help_new/help-95dot-com-frontend/pages/index/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: indexLkz37thPfVMeta?.name ?? "index",
    path: indexLkz37thPfVMeta?.path ?? "",
    meta: indexLkz37thPfVMeta || {},
    alias: indexLkz37thPfVMeta?.alias || [],
    redirect: indexLkz37thPfVMeta?.redirect || undefined,
    component: () => import("D:/office_work/95help_new/help-95dot-com-frontend/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: verify_45emailfWxV4wGFYZMeta?.name ?? "index-verify-email",
    path: verify_45emailfWxV4wGFYZMeta?.path ?? "verify-email",
    meta: verify_45emailfWxV4wGFYZMeta || {},
    alias: verify_45emailfWxV4wGFYZMeta?.alias || [],
    redirect: verify_45emailfWxV4wGFYZMeta?.redirect || undefined,
    component: () => import("D:/office_work/95help_new/help-95dot-com-frontend/pages/index/verify-email.vue").then(m => m.default || m)
  },
  {
    name: verify1YcyGBDbw4Meta?.name ?? "index-verify",
    path: verify1YcyGBDbw4Meta?.path ?? "verify",
    meta: verify1YcyGBDbw4Meta || {},
    alias: verify1YcyGBDbw4Meta?.alias || [],
    redirect: verify1YcyGBDbw4Meta?.redirect || undefined,
    component: () => import("D:/office_work/95help_new/help-95dot-com-frontend/pages/index/verify.vue").then(m => m.default || m)
  }
],
    name: indexQFlBjdhx92Meta?.name ?? undefined,
    meta: indexQFlBjdhx92Meta || {},
    alias: indexQFlBjdhx92Meta?.alias || [],
    redirect: indexQFlBjdhx92Meta?.redirect || undefined,
    component: () => import("D:/office_work/95help_new/help-95dot-com-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: jobsbBu0p3b54MMeta?.name ?? "jobs",
    path: jobsbBu0p3b54MMeta?.path ?? "/jobs",
    meta: jobsbBu0p3b54MMeta || {},
    alias: jobsbBu0p3b54MMeta?.alias || [],
    redirect: jobsbBu0p3b54MMeta?.redirect || undefined,
    component: () => import("D:/office_work/95help_new/help-95dot-com-frontend/pages/jobs.vue").then(m => m.default || m)
  },
  {
    name: loginZya9CSE8fHMeta?.name ?? "login",
    path: loginZya9CSE8fHMeta?.path ?? "/login",
    meta: loginZya9CSE8fHMeta || {},
    alias: loginZya9CSE8fHMeta?.alias || [],
    redirect: loginZya9CSE8fHMeta?.redirect || undefined,
    component: () => import("D:/office_work/95help_new/help-95dot-com-frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: _91orderId_93gQ3pQLNgJ7Meta?.name ?? "order-orderId",
    path: _91orderId_93gQ3pQLNgJ7Meta?.path ?? "/order/:orderId()",
    meta: _91orderId_93gQ3pQLNgJ7Meta || {},
    alias: _91orderId_93gQ3pQLNgJ7Meta?.alias || [],
    redirect: _91orderId_93gQ3pQLNgJ7Meta?.redirect || undefined,
    component: () => import("D:/office_work/95help_new/help-95dot-com-frontend/pages/order/[orderId].vue").then(m => m.default || m)
  },
  {
    name: cartQf3ExWWbQDMeta?.name ?? "order-cart",
    path: cartQf3ExWWbQDMeta?.path ?? "/order/cart",
    meta: cartQf3ExWWbQDMeta || {},
    alias: cartQf3ExWWbQDMeta?.alias || [],
    redirect: cartQf3ExWWbQDMeta?.redirect || undefined,
    component: () => import("D:/office_work/95help_new/help-95dot-com-frontend/pages/order/cart.vue").then(m => m.default || m)
  },
  {
    name: checkoutLMpdCwDdI8Meta?.name ?? "order-checkout",
    path: checkoutLMpdCwDdI8Meta?.path ?? "/order/checkout",
    meta: checkoutLMpdCwDdI8Meta || {},
    alias: checkoutLMpdCwDdI8Meta?.alias || [],
    redirect: checkoutLMpdCwDdI8Meta?.redirect || undefined,
    component: () => import("D:/office_work/95help_new/help-95dot-com-frontend/pages/order/checkout.vue").then(m => m.default || m)
  },
  {
    name: indexCk0KGUkNMVMeta?.name ?? "order",
    path: indexCk0KGUkNMVMeta?.path ?? "/order",
    meta: indexCk0KGUkNMVMeta || {},
    alias: indexCk0KGUkNMVMeta?.alias || [],
    redirect: indexCk0KGUkNMVMeta?.redirect || undefined,
    component: () => import("D:/office_work/95help_new/help-95dot-com-frontend/pages/order/index.vue").then(m => m.default || m)
  },
  {
    name: ordercartaWqGWMzlVVMeta?.name ?? "ordercart",
    path: ordercartaWqGWMzlVVMeta?.path ?? "/ordercart",
    meta: ordercartaWqGWMzlVVMeta || {},
    alias: ordercartaWqGWMzlVVMeta?.alias || [],
    redirect: ordercartaWqGWMzlVVMeta?.redirect || undefined,
    component: () => import("D:/office_work/95help_new/help-95dot-com-frontend/pages/ordercart.vue").then(m => m.default || m)
  },
  {
    name: privacypolicyzzPk1JIjoyMeta?.name ?? "privacypolicy",
    path: privacypolicyzzPk1JIjoyMeta?.path ?? "/privacypolicy",
    meta: privacypolicyzzPk1JIjoyMeta || {},
    alias: privacypolicyzzPk1JIjoyMeta?.alias || [],
    redirect: privacypolicyzzPk1JIjoyMeta?.redirect || undefined,
    component: () => import("D:/office_work/95help_new/help-95dot-com-frontend/pages/privacypolicy.vue").then(m => m.default || m)
  },
  {
    name: productswv9Mxme1VEMeta?.name ?? "products",
    path: productswv9Mxme1VEMeta?.path ?? "/products",
    meta: productswv9Mxme1VEMeta || {},
    alias: productswv9Mxme1VEMeta?.alias || [],
    redirect: productswv9Mxme1VEMeta?.redirect || undefined,
    component: () => import("D:/office_work/95help_new/help-95dot-com-frontend/pages/products.vue").then(m => m.default || m)
  },
  {
    name: register289UE8vvniMeta?.name ?? "register",
    path: register289UE8vvniMeta?.path ?? "/register",
    meta: register289UE8vvniMeta || {},
    alias: register289UE8vvniMeta?.alias || [],
    redirect: register289UE8vvniMeta?.redirect || undefined,
    component: () => import("D:/office_work/95help_new/help-95dot-com-frontend/pages/register.vue").then(m => m.default || m)
  },
  {
    name: setup_45profilejObaCJXsIbMeta?.name ?? "setup-profile",
    path: setup_45profilejObaCJXsIbMeta?.path ?? "/setup-profile",
    meta: setup_45profilejObaCJXsIbMeta || {},
    alias: setup_45profilejObaCJXsIbMeta?.alias || [],
    redirect: setup_45profilejObaCJXsIbMeta?.redirect || undefined,
    component: () => import("D:/office_work/95help_new/help-95dot-com-frontend/pages/setup-profile.vue").then(m => m.default || m)
  },
  {
    name: shippingandorderpolicydQbH5jUi7KMeta?.name ?? "shippingandorderpolicy",
    path: shippingandorderpolicydQbH5jUi7KMeta?.path ?? "/shippingandorderpolicy",
    meta: shippingandorderpolicydQbH5jUi7KMeta || {},
    alias: shippingandorderpolicydQbH5jUi7KMeta?.alias || [],
    redirect: shippingandorderpolicydQbH5jUi7KMeta?.redirect || undefined,
    component: () => import("D:/office_work/95help_new/help-95dot-com-frontend/pages/shippingandorderpolicy.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Dp86bHE0KyMeta?.name ?? "tag-id",
    path: _91id_93Dp86bHE0KyMeta?.path ?? "/tag/:id()",
    meta: _91id_93Dp86bHE0KyMeta || {},
    alias: _91id_93Dp86bHE0KyMeta?.alias || [],
    redirect: _91id_93Dp86bHE0KyMeta?.redirect || undefined,
    component: () => import("D:/office_work/95help_new/help-95dot-com-frontend/pages/tag/[id].vue").then(m => m.default || m)
  },
  {
    name: termsofusePzypsOabHyMeta?.name ?? "termsofuse",
    path: termsofusePzypsOabHyMeta?.path ?? "/termsofuse",
    meta: termsofusePzypsOabHyMeta || {},
    alias: termsofusePzypsOabHyMeta?.alias || [],
    redirect: termsofusePzypsOabHyMeta?.redirect || undefined,
    component: () => import("D:/office_work/95help_new/help-95dot-com-frontend/pages/termsofuse.vue").then(m => m.default || m)
  }
]